var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CommonPage',[_c('div',{staticClass:"index"},[_c('Wrapper',{attrs:{"color":"#121212"}},[_c('div',{staticClass:"top"},[_c('h1',[_vm._v("This Day In History")]),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad1}}),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(`${_vm.month} | ${_vm.day}`)+" ")])],1)]),_c(_vm.contentComponent,{tag:"component",attrs:{"curOne":_vm.curOne}}),_c('Wrapper',{attrs:{"color":"#ffffff"}},[_c('div',{staticClass:"b"},[_c('div',{staticClass:"son"},[_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad2}}),_c('div',{staticClass:"text"},[_vm._v("Also on This Day in History")]),_c('div',{staticClass:"hs"},[_vm._l((_vm.$store.state.list),function(item){return _c('div',{key:item?.title,staticClass:"itemBlock pointer",style:({ background: `url(${item?.img}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }),on:{"click":function($event){return _vm.$router.push({ name: 'detail', params: { id: item?.id } })}}},[_c('div',{staticClass:"e"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(item?.year))]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item?.title)+" ")])])}),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad3,"posi":{
								pc: {
									rowAt: 3,
									colNum: 3,
								},
								m: 3
							}}})],2)],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }